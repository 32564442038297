export default [
  {
    title: 'Usuarios',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Carpetas',
    route: 'folders',
    icon: 'FolderIcon',
  },
  {
    title: 'Logs',
    route: 'logs',
    icon: 'LogIcon',
  },
]
