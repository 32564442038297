<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 footer-text">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <span class="text-bold"> GH Induction </span>
      - 
      <a href="https://ghinduction.com/es-mx/privacidad//" target="_blank">
        {{ $t('Política de privacidad')}} 
      </a>
    </span>
    <span class="float-md-right d-block d-md-inline-block mt-25 footer-text ml-1">
       {{$t('Versión')}} 1.0.0
    </span>
  </p>
</template>

<script>
export default {};
</script>
